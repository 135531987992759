<template>
	<el-dialog :title="'公告'" append-to-body :close-on-click-modal="false" :visible.sync="dialog" width="800px" @closed="closedAlert">
		<div v-if="detData.length>0">
			<el-card class="" v-for="(item,index) in detData" :key="index">
				<template slot="header">
					<div style="display: flex; justify-content: space-between; align-items: center;">
						<h2 class="">{{item.msgText}}</h2>
						<div>{{item.noticeDate}}</div>
					</div>
				</template>
				<div>
					<!-- <div class="">
						<span>{{item.noticeDate}}</span>
					</div> -->
					<div class="ql-snow">
						<div class="ql-editor" v-html="item.content">
						</div>
					</div>
				</div>
				
			</el-card>
		</div>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" plain @click="dialog = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button>
		</div>
	</el-dialog>
	
</template>
<script>
	import 'quill/dist/quill.snow.css'
	export default {
		components: {},
		data() {
			return {
				dialog: false,
				loading_load: false,
				detData: [],
				detDataIds : "",
			};
		},
		activated() {
			
		},
		mounted() {
			
			this.initData();
		},
		methods: {
			initData() {
				this.detData = [];
				//初始化上传控件
				this.getData();
			},
			//关闭弹窗，赋值已读
			closedAlert(){
				localStorage.setItem('alertIsRead',this.detDataIds);
			},
			
			//请求分页数据
			getData() {
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhNoticeList, {
						"offset": "0",
						"limit": "10",
						"msgType":"30",
						"status":"10"
					})
					.then(({
						data
					}) => {
						console.log(data);
						this.loading_load = false;
						if (200 == data.code && data.rows) {
							this.detData = data.rows;
							if(this.detData.length>0){
								
								this.detData.forEach((item,index)=>{
									this.getConTextData(index);
								});
								
								this.detDataIds = this.detData.map(item=> item.id).join(',');
								//已阅读过,当前ids,不等于已存在的 localStorage
								if(this.detDataIds!=localStorage.getItem('alertIsRead')){
									this.dialog = true;
								}
								
							}
						}
					})
					.catch(error => {
						console.log(error);
						this.loading_load = false;
					});
			},
			//请求
			getConTextData(index) {
				// console.log('getConTextData',index);
				if(!this.detData[index]){
					return;
				}
				if(!!this.detData[index].content){
					return;
				}
				let key = this.detData[index].msgTextKey;
				this.$http
					.get(this.$urlConfig.WhNoticeMoreText + "?keyId=" + key, {})
					.then(({
						data
					}) => {
						console.log(data);
						if (200 == data.code && data.data) {
							let detData = this.detData[index];
							detData.content = data.data;
							this.$set(this.detData, detData, index);
							this.$forceUpdate();
						}
					})
					.catch(error => {
						console.log(error);
					});
			},
		}
	};
</script>

<style scoped lang="less">
	
</style>
